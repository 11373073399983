<template>
  <div>
    <b-form-row v-if="restrictAnnouncementBannerToMultipleCity">
      <b-col>
        <b-alert show variant="danger">{{ DISPLAY_MESSAGES.MULTIPLE_CITIES_ANNOUNCEMENT_BANNER_ERROR }}</b-alert>
      </b-col>
    </b-form-row>
    <template v-else-if="isAnnouncementEnabled">
      <LoaderIcon v-if="getAnnouncementBannerLoading" />
      <b-form v-else id="announcement-banner-from" name="announcement-banner-from" autocomplete="off" @submit.prevent="onSubmit" novalidate>
        <b-form-row>
          <b-col sm="10" md="6" lg="5" xl="3">
            <b-form-group>
              <div class="floating-input-field">
                <b-form-input
                  id="announcement-banner-title"
                  type="text"
                  placeholder=" "
                  v-model="formData.title"
                  required
                  :class="{
                    'is-invalid': !formData.title && formSubmitted,
                  }"
                ></b-form-input>
                <label for="announcement-banner-title">Announcement banner title</label>
                <b-form-invalid-feedback class="d-block" v-if="!formData.title && formSubmitted">Announcement banner title required.</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="10" md="6" lg="5" xl="4" class="pt-md-2 mt-md-1">
            <b-form-group>
              <b-form-checkbox id="show-announcement-banner" name="show-announcement-banner" v-model="formData.isEnabled">
                Show Announcement banner on Dashboard screen
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="7">
            <b-form-group>
              <div class="floating-input-field">
                <b-form-textarea
                  id="announcement-banner-text"
                  placeholder=" "
                  class="overflow-auto"
                  rows="7"
                  maxlength="500"
                  v-model="formData.text"
                  required
                  :class="{
                    'is-invalid': !formData.text && formSubmitted,
                  }"
                ></b-form-textarea>
                <label for="announcement-banner-text">Announcement banner text</label>
                <b-form-invalid-feedback class="d-block" v-if="!formData.text && formSubmitted">Announcement banner text required.</b-form-invalid-feedback>
                <b-form-invalid-feedback class="d-block" v-if="textRowValidation && formSubmitted">Maximum three rows allowed.</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="10" md="6" lg="5" xl="3">
            <b-form-group>
              <div class="floating-input-field">
                <datepicker
                  id="announcement-banner-expiry-date"
                  :bootstrapStyling="true"
                  :typeable="true"
                  :inputClass="{
                    'is-invalid': !formData.expiryDate && formSubmitted,
                  }"
                  :wrapperClass="{
                    'is-value-exist': formData.expiryDate,
                  }"
                  v-model="formData.expiryDate"
                  format="MM-dd-yyyy"
                ></datepicker>
                <label for="announcement-banner-expiry-date">Banner expiry date (23:59)</label>
                <b-form-invalid-feedback class="d-block" v-if="!formData.expiryDate && formSubmitted">Banner expiry date required.</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="10" md="6" lg="5" xl="3">
            <h4 class="page-sub-header">Announcement banner icon</h4>
            <b-form-group>
              <button
                type="button"
                id="announcement-banner-select-icon"
                class="btn-block select-icon-btn preview-logo-wraper"
                @click="showIconsModal = true"
                :class="{
                  'is-invalid': !formData.iconBlobURI && formSubmitted,
                }"
              >
                <span
                  :style="{
                    backgroundColor: formData.bannerColor ? formData.bannerColor : `#ccc`,
                  }"
                  class="preview-logo"
                  ><img v-if="formData.iconBlobURI" :src="formData.iconBlobURI" class="icon-selected" /></span
                >{{ formData.iconBlobURI ? 'Change icon' : 'Select icon' }}
              </button>
              <IconsList v-if="showIconsModal" :savedIcon="formData.iconBlobURI" @closeIconsModal="closeIconsModal" />
              <b-form-invalid-feedback class="d-block pb-2" v-if="!formData.iconBlobURI && formSubmitted"
                >Announcement banner icon required.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="10" md="6" lg="5" xl="3">
            <b-form-group>
              <h4 class="page-sub-header">Announcement banner color</h4>
              <div class="position-relative">
                <b-form-input
                  id="announcement-banner-color"
                  type="text"
                  placeholder="Announcement banner color"
                  class="custom-color-input"
                  :class="{
                    'is-invalid': !formData.bannerColor && formSubmitted,
                    valueExist: formData.bannerColor,
                  }"
                  v-model="formData.bannerColor"
                  @focus="colorPickerToggle.bannerColor = true"
                  @blur="colorPickerToggle.bannerColor = false"
                  @input="applyColorToPicker('bannerColor')"
                  required
                ></b-form-input>
                <color-picker
                  v-if="colorPickerToggle.bannerColor"
                  theme="dark"
                  :color="formData.bannerColor"
                  :sucker-hide="true"
                  @input="onChangeColor($event, 'bannerColor')"
                  @changeColor="onChangeColor($event, 'bannerColor')"
                  class="color-picker"
                />
                <div class="preview-input-color-wraper" v-if="formData.bannerColor">
                  <div
                    class="preview-input-color"
                    :style="{
                      backgroundColor: formData.bannerColor,
                    }"
                  ></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!formData.bannerColor && formSubmitted"
                >Announcement banner color required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback :state="isValidColor('bannerColor')">Invalid color</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="10" md="6" lg="5" xl="3">
            <b-form-group>
              <h4 class="page-sub-header">Announcement banner text color</h4>
              <div class="position-relative">
                <b-form-input
                  id="announcement-banner-text-color"
                  type="text"
                  placeholder="Announcement banner text color"
                  class="custom-color-input"
                  :class="{
                    'is-invalid': !formData.textColor && formSubmitted,
                    valueExist: formData.textColor,
                  }"
                  v-model="formData.textColor"
                  @focus="colorPickerToggle.bannerTextColor = true"
                  @blur="colorPickerToggle.bannerTextColor = false"
                  @input="applyColorToPicker('bannerTextColor')"
                  required
                ></b-form-input>
                <color-picker
                  v-if="colorPickerToggle.bannerTextColor"
                  theme="dark"
                  :color="formData.textColor"
                  :sucker-hide="true"
                  @input="onChangeColor($event, 'textColor')"
                  @changeColor="onChangeColor($event, 'textColor')"
                  class="color-picker"
                />
                <div class="preview-input-color-wraper" v-if="formData.textColor">
                  <div
                    class="preview-input-color"
                    :style="{
                      backgroundColor: formData.textColor,
                    }"
                  ></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!formData.textColor && formSubmitted"
                >Announcement banner text color required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback :state="isValidColor('textColor')">Invalid color</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="my-4">
          <b-col>
            <b-button type="submit" id="save-announcement-banner-btn" v-activeBlur variant="primary" :disabled="updateAnnouncementBannerLoading"
              >Save <b-spinner v-if="updateAnnouncementBannerLoading" label="Spinning" small class="ml-2"></b-spinner
            ></b-button>
            <b-button type="button" id="cancel-announcement-banner-btn" v-activeBlur @click="onReset()" class="ml-3" variant="outline-secondary"
              >Cancel</b-button
            >
          </b-col>
        </b-form-row>
      </b-form>
    </template>
    <b-form-row v-else>
      <b-col>
        <b-alert show variant="danger">{{ DISPLAY_MESSAGES.ANNOUNCEMENT_BANNER_ENABLE_ERROR }}</b-alert>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import IconsList from '@/components/widgets/add-edit-widget/IconsList.vue'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import colorPicker from '@caohenghu/vue-colorpicker'
import { useAxios } from '@/composables/useAxios'
import { ServiceUrls } from '@/utilities/serviceUrls'
import { tenantSpecificURL } from '@/utilities/utilities'
import { useValidateFields } from '@/composables/useValidateFields'
import { DISPLAY_MESSAGES, ColorRegEx } from '@/utilities/constants'
import LoaderIcon from '@/assets/svg/loader.svg'
export default {
  name: 'AnnouncementBanner',
  setup() {
    const { scrollToErrorMessage } = useValidateFields()
    const {
      isLoading: updateAnnouncementBannerLoading,
      response: updateAnnouncementBannerResponse,
      error: updateAnnouncementBannerError,
      callApi: updateAnnouncementBannerApi,
    } = useAxios()
    const {
      isLoading: getAnnouncementBannerLoading,
      response: getAnnouncementBannerResponse,
      error: getAnnouncementBannerError,
      callApi: getAnnouncementBannerApi,
    } = useAxios()
    return {
      scrollToErrorMessage,
      updateAnnouncementBannerLoading,
      updateAnnouncementBannerResponse,
      updateAnnouncementBannerError,
      updateAnnouncementBannerApi,
      getAnnouncementBannerLoading,
      getAnnouncementBannerResponse,
      getAnnouncementBannerError,
      getAnnouncementBannerApi,
    }
  },
  data() {
    return {
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      formData: {
        tenantId: 0,
        isEnabled: true,
        title: null,
        text: null,
        bannerColor: null,
        textColor: null,
        iconBlobURI: null,
        expiryDate: null,
      },
      cloneFormData: null,
      showIconsModal: false,
      formSubmitted: false,
      colorPickerToggle: {
        bannerColor: false,
        bannerTextColor: false,
      },
    }
  },
  components: {
    Datepicker,
    IconsList,
    colorPicker,
    LoaderIcon,
  },
  computed: {
    restrictAnnouncementBannerToMultipleCity() {
      return this.selectedTenant.length > 1 || this.selectedTenant.some((tenant) => tenant.tenantID === 0)
    },
    isAnnouncementEnabled() {
      return this.selectedTenant && this.selectedTenant.length ? this.selectedTenant[0].isAnnouncementEnabled : false
    },
    isValidColor() {
      return (property) => {
        if (this.formData[property]) {
          return this.formData[property].match(ColorRegEx) !== null
        }
        return true
      }
    },
    textRowValidation() {
      const textRows = this.formData.text ? this.formData.text.split('\n') : []
      return textRows.length > 3
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
    }),
  },
  mounted() {
    this.cloneFormData = cloneDeep(this.formData)
    this.getAnnouncementBannerDetails()
  },
  methods: {
    showToaster(message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type,
      })
    },
    onChangeColor(color, property) {
      this.formData[property] = color.hex
    },
    applyColorToPicker(property) {
      this.colorPickerToggle[property] = false
      this.$nextTick(() => {
        this.colorPickerToggle[property] = true
      })
    },
    closeIconsModal(event) {
      if (event) {
        this.formData.iconBlobURI = event
      }
      this.showIconsModal = false
    },
    async getAnnouncementBannerDetails() {
      if (!this.restrictAnnouncementBannerToMultipleCity && this.isAnnouncementEnabled) {
        await this.getAnnouncementBannerApi({ method: 'get', url: tenantSpecificURL(ServiceUrls.getAnnouncementDetails) })
        if (this.getAnnouncementBannerResponse) {
          this.formData = cloneDeep(this.getAnnouncementBannerResponse)
        }
        if (this.getAnnouncementBannerError) {
          this.showToaster(false, 'FAILED', 'danger')
        }
      }
      if (!this.isAnnouncementEnabled) {
        this.$router.push({ name: 'Widgest' }).catch(() => {})
      }
    },
    validateForm() {
      const title = !!this.formData.title
      const text = !!this.formData.text
      const expiryDate = !!this.formData.expiryDate
      const icon = !!this.formData.iconBlobURI
      const bannerColor = !!this.formData.bannerColor
      const bannerTextColor = !!this.formData.textColor
      return title && text && !this.textRowValidation && expiryDate && icon && bannerColor && bannerTextColor
    },
    async onSubmit() {
      this.formSubmitted = true
      this.scrollToErrorMessage()
      if (this.validateForm()) {
        this.formData.tenantId = this.selectedTenant[0].tenantID
        this.formData.expiryDate = `${moment(this.formData.expiryDate).format('YYYY-MM-DD')}T23:59:59`
        await this.updateAnnouncementBannerApi({ method: 'post', url: ServiceUrls.updateAnnouncementDetails, data: this.formData })
        if (this.updateAnnouncementBannerResponse) {
          this.showToaster(false, 'UPDATE_ANNOUNCEMENT_BANNER_SUCCESS', 'success')
        }
        if (this.updateAnnouncementBannerError) {
          if (this.updateAnnouncementBannerError?.errorMessage) {
            this.showToaster(this.updateAnnouncementBannerError?.errorMessage, false, 'danger')
          } else {
            this.showToaster(false, 'FAILED', 'danger')
          }
        }
      }
    },
    onReset() {
      this.formData = cloneDeep(this.cloneFormData)
      this.formSubmitted = false
    },
  },
  watch: {
    selectedTenant() {
      this.getAnnouncementBannerDetails()
    },
  },
}
</script>
