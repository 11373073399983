var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.restrictAnnouncementBannerToMultipleCity)?_c('b-form-row',[_c('b-col',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(_vm._s(_vm.DISPLAY_MESSAGES.MULTIPLE_CITIES_ANNOUNCEMENT_BANNER_ERROR))])],1)],1):(_vm.isAnnouncementEnabled)?[(_vm.getAnnouncementBannerLoading)?_c('LoaderIcon'):_c('b-form',{attrs:{"id":"announcement-banner-from","name":"announcement-banner-from","autocomplete":"off","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-row',[_c('b-col',{attrs:{"sm":"10","md":"6","lg":"5","xl":"3"}},[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                  'is-invalid': !_vm.formData.title && _vm.formSubmitted,
                },attrs:{"id":"announcement-banner-title","type":"text","placeholder":" ","required":""},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('label',{attrs:{"for":"announcement-banner-title"}},[_vm._v("Announcement banner title")]),(!_vm.formData.title && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Announcement banner title required.")]):_vm._e()],1)])],1),_c('b-col',{staticClass:"pt-md-2 mt-md-1",attrs:{"sm":"10","md":"6","lg":"5","xl":"4"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"show-announcement-banner","name":"show-announcement-banner"},model:{value:(_vm.formData.isEnabled),callback:function ($$v) {_vm.$set(_vm.formData, "isEnabled", $$v)},expression:"formData.isEnabled"}},[_vm._v(" Show Announcement banner on Dashboard screen ")])],1)],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"7"}},[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('b-form-textarea',{staticClass:"overflow-auto",class:{
                  'is-invalid': !_vm.formData.text && _vm.formSubmitted,
                },attrs:{"id":"announcement-banner-text","placeholder":" ","rows":"7","maxlength":"500","required":""},model:{value:(_vm.formData.text),callback:function ($$v) {_vm.$set(_vm.formData, "text", $$v)},expression:"formData.text"}}),_c('label',{attrs:{"for":"announcement-banner-text"}},[_vm._v("Announcement banner text")]),(!_vm.formData.text && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Announcement banner text required.")]):_vm._e(),(_vm.textRowValidation && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Maximum three rows allowed.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"10","md":"6","lg":"5","xl":"3"}},[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('datepicker',{attrs:{"id":"announcement-banner-expiry-date","bootstrapStyling":true,"typeable":true,"inputClass":{
                  'is-invalid': !_vm.formData.expiryDate && _vm.formSubmitted,
                },"wrapperClass":{
                  'is-value-exist': _vm.formData.expiryDate,
                },"format":"MM-dd-yyyy"},model:{value:(_vm.formData.expiryDate),callback:function ($$v) {_vm.$set(_vm.formData, "expiryDate", $$v)},expression:"formData.expiryDate"}}),_c('label',{attrs:{"for":"announcement-banner-expiry-date"}},[_vm._v("Banner expiry date (23:59)")]),(!_vm.formData.expiryDate && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Banner expiry date required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"10","md":"6","lg":"5","xl":"3"}},[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Announcement banner icon")]),_c('b-form-group',[_c('button',{staticClass:"btn-block select-icon-btn preview-logo-wraper",class:{
                'is-invalid': !_vm.formData.iconBlobURI && _vm.formSubmitted,
              },attrs:{"type":"button","id":"announcement-banner-select-icon"},on:{"click":function($event){_vm.showIconsModal = true}}},[_c('span',{staticClass:"preview-logo",style:({
                  backgroundColor: _vm.formData.bannerColor ? _vm.formData.bannerColor : `#ccc`,
                })},[(_vm.formData.iconBlobURI)?_c('img',{staticClass:"icon-selected",attrs:{"src":_vm.formData.iconBlobURI}}):_vm._e()]),_vm._v(_vm._s(_vm.formData.iconBlobURI ? 'Change icon' : 'Select icon')+" ")]),(_vm.showIconsModal)?_c('IconsList',{attrs:{"savedIcon":_vm.formData.iconBlobURI},on:{"closeIconsModal":_vm.closeIconsModal}}):_vm._e(),(!_vm.formData.iconBlobURI && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block pb-2"},[_vm._v("Announcement banner icon required.")]):_vm._e()],1)],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"10","md":"6","lg":"5","xl":"3"}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Announcement banner color")]),_c('div',{staticClass:"position-relative"},[_c('b-form-input',{staticClass:"custom-color-input",class:{
                  'is-invalid': !_vm.formData.bannerColor && _vm.formSubmitted,
                  valueExist: _vm.formData.bannerColor,
                },attrs:{"id":"announcement-banner-color","type":"text","placeholder":"Announcement banner color","required":""},on:{"focus":function($event){_vm.colorPickerToggle.bannerColor = true},"blur":function($event){_vm.colorPickerToggle.bannerColor = false},"input":function($event){return _vm.applyColorToPicker('bannerColor')}},model:{value:(_vm.formData.bannerColor),callback:function ($$v) {_vm.$set(_vm.formData, "bannerColor", $$v)},expression:"formData.bannerColor"}}),(_vm.colorPickerToggle.bannerColor)?_c('color-picker',{staticClass:"color-picker",attrs:{"theme":"dark","color":_vm.formData.bannerColor,"sucker-hide":true},on:{"input":function($event){return _vm.onChangeColor($event, 'bannerColor')},"changeColor":function($event){return _vm.onChangeColor($event, 'bannerColor')}}}):_vm._e(),(_vm.formData.bannerColor)?_c('div',{staticClass:"preview-input-color-wraper"},[_c('div',{staticClass:"preview-input-color",style:({
                    backgroundColor: _vm.formData.bannerColor,
                  })})]):_vm._e()],1),(!_vm.formData.bannerColor && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Announcement banner color required.")]):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.isValidColor('bannerColor')}},[_vm._v("Invalid color")])],1)],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"10","md":"6","lg":"5","xl":"3"}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Announcement banner text color")]),_c('div',{staticClass:"position-relative"},[_c('b-form-input',{staticClass:"custom-color-input",class:{
                  'is-invalid': !_vm.formData.textColor && _vm.formSubmitted,
                  valueExist: _vm.formData.textColor,
                },attrs:{"id":"announcement-banner-text-color","type":"text","placeholder":"Announcement banner text color","required":""},on:{"focus":function($event){_vm.colorPickerToggle.bannerTextColor = true},"blur":function($event){_vm.colorPickerToggle.bannerTextColor = false},"input":function($event){return _vm.applyColorToPicker('bannerTextColor')}},model:{value:(_vm.formData.textColor),callback:function ($$v) {_vm.$set(_vm.formData, "textColor", $$v)},expression:"formData.textColor"}}),(_vm.colorPickerToggle.bannerTextColor)?_c('color-picker',{staticClass:"color-picker",attrs:{"theme":"dark","color":_vm.formData.textColor,"sucker-hide":true},on:{"input":function($event){return _vm.onChangeColor($event, 'textColor')},"changeColor":function($event){return _vm.onChangeColor($event, 'textColor')}}}):_vm._e(),(_vm.formData.textColor)?_c('div',{staticClass:"preview-input-color-wraper"},[_c('div',{staticClass:"preview-input-color",style:({
                    backgroundColor: _vm.formData.textColor,
                  })})]):_vm._e()],1),(!_vm.formData.textColor && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Announcement banner text color required.")]):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.isValidColor('textColor')}},[_vm._v("Invalid color")])],1)],1)],1),_c('b-form-row',{staticClass:"my-4"},[_c('b-col',[_c('b-button',{directives:[{name:"activeBlur",rawName:"v-activeBlur"}],attrs:{"type":"submit","id":"save-announcement-banner-btn","variant":"primary","disabled":_vm.updateAnnouncementBannerLoading}},[_vm._v("Save "),(_vm.updateAnnouncementBannerLoading)?_c('b-spinner',{staticClass:"ml-2",attrs:{"label":"Spinning","small":""}}):_vm._e()],1),_c('b-button',{directives:[{name:"activeBlur",rawName:"v-activeBlur"}],staticClass:"ml-3",attrs:{"type":"button","id":"cancel-announcement-banner-btn","variant":"outline-secondary"},on:{"click":function($event){return _vm.onReset()}}},[_vm._v("Cancel")])],1)],1)],1)]:_c('b-form-row',[_c('b-col',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(_vm._s(_vm.DISPLAY_MESSAGES.ANNOUNCEMENT_BANNER_ENABLE_ERROR))])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }